<template>
  <div class="pin-pad__input" :class="classes">
    <v-otp-input v-bind="$attrs" type="password" readonly />
    <div class="pin-pad__error-message text-body-2">
      <v-slide-y-transition>
        <span v-if="hasError">{{ errorMessage }}</span>
      </v-slide-y-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "PinPadInput",
  inheritAttrs: false,
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      return { "pin-pad__input--error": this.hasError };
    },
    hasError() {
      return this.errorMessage.length > 0;
    },
  },
};
</script>

<style lang="scss">
.pin-pad__input .v-input {
  border-radius: 10px;

  input {
    font-size: 38px !important;
  }
}

.pin-pad__input--error .v-input fieldset {
  border-color: var(--v-error-base) !important;
  border-width: 2px;
}

.pin-pad__error-message {
  color: var(--v-error-base) !important;
  text-align: center;
  min-height: 20px;
  min-width: 1px;
}
</style>
