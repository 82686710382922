<template>
  <button
    type="button"
    data-testid="pinPadKey"
    :class="['pin-pad__key text-data', classes]"
    :disabled="disabled"
    @click="keyup"
  >
    <slot v-bind="{ disabled }">
      {{ value }}
    </slot>
  </button>
</template>

<script>
const pinPadKeyMap = new Map([
  ["1", "Digit1"],
  ["2", "Digit2"],
  ["3", "Digit3"],
  ["4", "Digit4"],
  ["5", "Digit5"],
  ["6", "Digit6"],
  ["7", "Digit7"],
  ["8", "Digit8"],
  ["9", "Digit9"],
  ["0", "Digit0"],
  ["Escape", "Escape"],
  ["Backspace", "Backspace"],
]);

export default {
  name: "PinPadKey",
  inject: ["pinPad"],
  props: {
    value: {
      type: String,
      required: true,
      validator: (value) => pinPadKeyMap.has(value),
    },
  },
  computed: {
    isDigit() {
      return Number.isInteger(this.value * 1);
    },
    isBackspace() {
      return this.value === "Backspace";
    },
    isEscape() {
      return this.value === "Escape";
    },
    classes() {
      return { "text--disabled": this.disabled };
    },
    disabled() {
      const { disabled, loading, isValid } = this.pinPad ?? {};
      return disabled || loading || (isValid && this.isDigit);
    },
  },
  created() {
    this.pinPad?.addKey(this);
  },
  methods: {
    keyup() {
      const code = pinPadKeyMap.get(this.value);
      const keyboardEvent = new KeyboardEvent("keyup", {
        key: this.value,
        code,
      });

      window.dispatchEvent(keyboardEvent);
      this.$emit("keyup", keyboardEvent);
    },
  },
};
</script>

<style lang="scss">
.pin-pad__key {
  border-radius: 50%;
  font-size: 48px;
  font-weight: 200;
  width: 64px;
  height: 64px;

  &:focus {
    outline-style: none;
  }
}
</style>
