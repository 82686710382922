<template>
  <v-sheet v-bind="$attrs" data-testid="pinPad" class="pin-pad">
    <slot name="label">
      <div v-if="label" class="pin-pad__label">{{ label }}:</div>
    </slot>
    <div class="pin-pad-row">
      <PinPadInput
        :value="pinValue"
        :disabled="disabled || loading"
        :error-message="errorMessage"
        :length="pinLength"
      />
    </div>
    <div class="pin-pad-row">
      <PinPadKey value="1" />
      <PinPadKey value="2" />
      <PinPadKey value="3" />
    </div>
    <div class="pin-pad-row">
      <PinPadKey value="4" />
      <PinPadKey value="5" />
      <PinPadKey value="6" />
    </div>
    <div class="pin-pad-row">
      <PinPadKey value="7" />
      <PinPadKey value="8" />
      <PinPadKey value="9" />
    </div>
    <div class="pin-pad-row">
      <PinPadKey v-slot="key" value="Escape">
        <v-icon size="38" :disabled="key.disabled">$close</v-icon>
      </PinPadKey>
      <PinPadKey value="0" />
      <PinPadKey v-slot="key" value="Backspace">
        <v-icon size="38" :disabled="key.disabled">$backspace</v-icon>
      </PinPadKey>
    </div>
  </v-sheet>
</template>

<script>
import { memoize } from "lodash";
import PinPadKey from "./PinPadKey.vue";
import PinPadInput from "./PinPadInput.vue";

export default {
  name: "PinPad",
  provide() {
    return { pinPad: this };
  },
  components: { PinPadKey, PinPadInput },
  props: {
    label: {
      type: String,
      default: "",
    },
    pinLength: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    pinValue: "",
    keys: [],
  }),
  computed: {
    findKey() {
      return memoize(function (key) {
        return this.keys.find((x) => x.value === key);
      });
    },
    isValid() {
      return this.pinValue.length === this.pinLength;
    },
  },
  watch: {
    pinValue: {
      handler(value, oldValue) {
        if (value !== oldValue && value.length === this.pinLength) {
          this.$emit("finish", this.pinValue);
        }
      },
    },
  },
  created() {
    window.addEventListener("keyup", this.onKeyup);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (this.loading) {
        return;
      }

      const key = this.findKey(e.key);

      if (!key) {
        return;
      }

      if (key.isDigit) {
        this.addDigit(key.value);
      } else if (key.isBackspace) {
        this.removeDigit();
      } else if (key.isEscape) {
        this.clear();
      }

      if (this.pinValue && this.errorMessage?.length > 0) {
        this.$emit("update:error-message", "");
      }
    },
    addKey(key) {
      this.keys.push(key);
    },
    addDigit(value) {
      if (this.pinValue.length >= this.pinLength) {
        return;
      }

      this.pinValue += value;
    },
    removeDigit() {
      if (this.pinValue.length <= 0) {
        return;
      }

      this.pinValue = this.pinValue.substring(0, this.pinValue.length - 1);
    },
    clear() {
      this.pinValue = "";
      this.$emit("clear");
    },
  },
};
</script>

<style lang="scss">
.pin-pad-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.pin-pad__label {
  padding: 16px 0px;
}
</style>
